import React, { useState, useRef } from 'react';
import { Link } from "gatsby";
import { Head } from "gatsby-theme-landing-page";
import ContactForm from "gatsby-theme-landing-page/src/components/call-me-back-form"
import "../styles.css";
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import * as cssVars from "gatsby-theme-landing-page/src/styles/variables.module.css";
import * as styles from "./layout.module.css";

export default function Layout(props) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <div className={[cssVars.root, styles.root].join(" ")}>
      <Head {...props} />
      <header className={styles.header}>
        <Link to="/">AMMIPRODUCT</Link>
      </header>
      <main className={styles.main}>{props.children}</main>
      <footer className={styles.footer}>
      <Button variant='link' ref={target} onClick={() => setShow(!show)}>
        Company Info
      </Button>
      <Overlay target={target.current} show={show} placement="top">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            <p>In English: "AMMIPRODUCT" LIMITED LIABILITY Company</p>
            <p>SHORT VERSION: "AMMIPRODUCT" LLC</p>
            <p>Registration number of tax payer: 02299308</p>
          </Tooltip>
        )}
      </Overlay>
        <ContactForm></ContactForm>
      </footer>
    </div>
  );
}
