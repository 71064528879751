import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage, useField, useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import MaskedInput from "react-text-mask";
import "./call-me-back-form.css";

/* const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/ */

const phoneNumberMask = [
    "8",
    " ",
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];
const MyCheckbox = ({ children, ...props }) => {
    const [field, meta] = useField({ ...props, type: "checkbox" });
    return (
        <>
            <label>
                <input {...field} {...props} type="checkbox" />
                {children}
            </label>
            {meta.touched && meta.error ? (
                <div className="error text-danger mt-n2 mb-2">{meta.error}</div>
            ) : null}
        </>
    );
};
const formSchema = Yup.object().shape({
    fullName: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .max(60, 'Must be no more than 60 characters')
        .required("Required field"),
    emailAddr: Yup.string().email('Not a proper email').required("Email is required"),
    textMessage: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(256, 'Must be no more than 256 characters')
    .required("Required field"),
    acceptedTerms: Yup.boolean()
        .required("Required field")
        .oneOf([true], "Consent to the processing of personal data is required"),
    phone: Yup.string().notRequired()
});


export default () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    /* Server State Handling */
    const [serverState, setServerState] = useState();
    const handleServerResponse = (ok, msg) => {
        setServerState({ ok, msg });
    };
    const handleOnSubmit = (values, actions) => {
        axios({
            method: "POST",
            url: "https://formspree.io/f/xzbwdqdw",
            data: values
        })
            .then(response => {
                actions.setSubmitting(false);
                actions.resetForm();
                handleServerResponse(true, "Thank you, we will contact you soon!");
            })
            .catch(error => {
                actions.setSubmitting(false);
                handleServerResponse(false, error.response.data.error);
            });
    };


    return (
        <>
            <Button variant="link" className="btn" style={{ backgroundColor: "#1d75b8", color: "white", textDecoration: "none" }} onClick={handleShow}>
                Contact us
                    </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="mx-auto" style={{ padding: '1rem 1rem', }}>Drop us a line</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{ fullName: "", textMessage: "", emailAddr: "", phone: "", acceptedTerms: false }}
                        onSubmit={handleOnSubmit}
                        validationSchema={formSchema}
                    >

                        {({ isSubmitting }) => (
                            <Form id="fs-frm" noValidate>
                                <label htmlFor="full-name" className="font-weight-bold mb-1">Full name</label>
                                <Field className="form-control d-block mb-3" id="full-name" name="fullName" type="text" />
                                <ErrorMessage name="fullName" className="errorMsg d-block mt-n3 text-danger mb-3" component="p" />
                                <label htmlFor="email-addr" className="font-weight-bold mb-1">Email</label>
                                <Field className="form-control d-block mb-3" id="email-addr" name="emailAddr" type="email" />
                                <ErrorMessage name="emailAddr" className="errorMsg d-block mt-n3 text-danger mb-3" component="p" />
                                <label htmlFor="text-message" className="font-weight-bold mb-1">Message</label>
                                <Field className="form-control d-block mb-3" id="text-message" name="textMessage" type="text" />
                                <ErrorMessage name="textMessage" className="errorMsg d-block mt-n3 text-danger mb-3" component="p" />
                                <label htmlFor="phone" className="font-weight-bold mb-1">Phone number (optional)</label>
                                <Field
                                    name="phone"
                                    render={({ field }) => (
                                        <MaskedInput
                                            {...field}
                                            mask={phoneNumberMask}
                                            id="phone"
                                            placeholder="8 (___) ___-____"
                                            type="text"
                                            className="form-control d-block mb-3"
                                        />
                                    )} />
                                <MyCheckbox name="acceptedTerms">
                                <p className="d-inline" style={{ marginLeft: "5px" }}>I agree to the processing of personal data</p>
                                        </MyCheckbox>
                                <button type="submit" className="btn btn-dark my-3 d-block" disabled={isSubmitting}>
                                    Submit
                                        </button>
                                {serverState && (
                                    <p className={!serverState.ok ? "errorMsg" : "text-success"}>
                                        {serverState.msg}
                                    </p>
                                )}
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};
